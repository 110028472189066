<template>

    <div id="offcanvas-cart" uk-offcanvas="overlay: true; mode: slide;"
         :class="cartClass"
         style="display: block;"
         ref="cartWrapper" >
        <div class="cart-bar uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide uk-background-default uk-padding-remove">
            <!-- bar header-->
            <div class="cart-title uk-panel uk-background-primary ">
                <span class="uk-text-background">
                     <span class="uk-margin-small-right" uk-icon="icon:cart; ratio:1.3"></span><span class="cart-title-text">Your Cart</span>
                </span>
                <div class="cart-close" uk-close="ration: 1.5" @click="closeCart()"></div>
            </div>

            <cart></cart>

            <!-- bar footer - CTA -->
            <div v-if=" ! isEmpty" class="cart-proceed-wrapper">
                <form-button
                        class="cart-proceed "
                        size="large"
                        type="primary"
                        :fullWidth="true"
                        iconEnd="chevron-right">PROCEED TO CHECKOUT</form-button>

            </div>
        </div>
    </div>

</template>

<script>
    import {computed} from 'vue';
    import { useStore } from 'vuex'
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import Cart from "@/client/components/ecommerce/Cart.vue";

    export default {
        setup (props) {
            let {asyncOps, asyncOpsReady} = asyncOperations(props, useStore());

            return {asyncOps, asyncOpsReady}
        },
        props: {

        },
        components: {
             Cart
        },

        data: function () {
            return {
                isEmpty: false,
                cartOpen: computed( () => this.$store.getters['cart/open']),
            };
        },
        computed: {
            cartClass () {
                let cartClass = ['uk-offcanvas'];
                return cartClass;
            },
        },
        methods: {
            closeCart() {
                this.$store.commit('cart/setOpen', false);
                this.closeCartDom();
            },

            openCart() {
                this.$store.commit('cart/setOpen', true);
                this.openCartDom();
            },
            openCartDom() {
                this.$s.ui.adapter.offcanvas(this.$refs.cartWrapper).show();
            },
            closeCartDom() {
                this.$s.ui.adapter.offcanvas(this.$refs.cartWrapper).hide();
            },
            syncCartState() {
                this.cartOpen ? this.openCart() : this.closeCartDom();
            },
        },
        watch : {
            cartOpen : 'syncCartState'
        },

        created () {

        },
        mounted () {
            // make sure state and dom are synced as far as opening of the cart goes
            this.syncCartState();
            this.$refs.cartWrapper.addEventListener('hide', () => {
                this.$store.commit('cart/setOpen', false);
            });

            // dev - open cart immediatly
           // this.openCartDom();

        }
    }
</script>

<style scoped lang="scss">
    .cart-main {
        position: relative;
    }

    .cart-bar {
        left: -450px;
        width: 450px;
    }

    .uk-open > .cart-bar {
        left: 0;
    }

    .cart-main > .cart-empty-text {
       color: var(--global-color);
   }

    .cart-title {
        position: relative;
        padding-top: calc(var(--global-small-gutter)*0.2);
        padding-right: calc(var(--global-small-gutter)*0.8);
        padding-bottom:calc(var(--global-small-gutter)*0.6);
        padding-left: calc(var(--global-small-gutter)*0.8);
    }

    .cart-proceed-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        overflow: hidden;
        background: var(--global-secondary-background);
        padding: var(--global-small-gutter);
    }

    .cart-proceed {
        overflow: hidden;
        background: var(--global-primary-background);
        color: var(--global-background);
        transition: all 250ms;

        &:hover {
            background: var(--global-background);
            color: var(--global-primary-background);
        }
    }

    .cart-close {
        background: var(--global-secondary-background);
        color: var(--global-background);
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        width: 48px;
        height: 100%;
        cursor: pointer;
    }

    .cart-bar  .cart-title  * {
        color: var(--global-background);
    }

    .uk-offcanvas .cart-proceed-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .uk-button {
            &:hover {
                background-color: var(--global-background)!important;
                color: var(--global-primary-background)!important;
            }
        }

    }
</style>
